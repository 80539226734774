import { getLongTermGasBalancePredictionHistory } from "../../../apis/vitusApi";
import { ILongTermGasBalancePredictionHistoryResponse } from "../../../apis/vitusApiTypes";
import { ReportParams, ReportView } from "../../../system/ReportBase";
import AlertManager from "../../../utils/alertManager";
import { Month, handleApiError, months } from "../../../utils/common";
import messages from "../../../utils/messages";
import { createSpinner } from "../../../utils/spinnerManager";
import ReportViewer from "../../ReportViewer/ReportViewer";
import { GridSize, Button, Grid, Typography } from "@material-ui/core";
import VPageFilter from "../../../components/VPageFilter/VPageFilter";
import VSelect from "../../../components/VSelect/VSelect";
import { isEmpty } from "lodash";
import VChart from '../../../components/VChart/VChart';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageManager';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

echarts.use(
    [LineChart, GridComponent]
);

const minYear = 2022; // min data year is 2022
const maxYear = new Date().getFullYear() + 2;
const years = Array.from(Array(maxYear - minYear + 1).keys()).map(k => (k + minYear).toString())

type Filter = {
    month: Month,
    year: string,
}

interface IState {
    activeFilter: Filter,
    selectedFilter: Filter,
    predictions: ILongTermGasBalancePredictionHistoryResponse["success"]["predictions"],
}

class PredictionReport extends ReportView<{}, IState> {
    static params: ReportParams = new ReportParams(
        {
            reportKey: "PREDICTION_REPORT",
            name: "Long Term Europe Gas Balance Prediction History",
            path: "/predictionReport",
            thumbnail: ""
        }
    );

    chartRef: ReactECharts | null = null;

    localFilter = getLocalStorage(PredictionReport.params.reportKey, "filter", "mainFilter");

    initialFilter: Filter = {
        month: this.localFilter?.month || months[new Date().getMonth()],
        year: this.localFilter?.year || new Date().getFullYear().toString(),
    };

    state: IState = {
        selectedFilter: { ...this.initialFilter },
        activeFilter: { ...this.initialFilter },
        predictions: [],
    }

    defaultChartOptions: ECOption = {
        animation: false,
        xAxis: {
            name: 'Prediction Date',
            type: 'category',
        },
        yAxis: {
            name: 'Value',
            type: 'value',
            scale: true,
        },
        series: [{
            data: [],
            type: 'line'
        }],
    };

    componentDidMount() {
        this.getData();
    }

    getData(pFilter?: IState["activeFilter"]) {
        const spinner = createSpinner();

        const filter = pFilter || this.state.activeFilter;

        getLongTermGasBalancePredictionHistory({
            month: months.indexOf(filter.month) + 1,
            year: +filter.year,
        }).then(response => {
            if (response.data.success) {
                if (isEmpty(response.data.success.predictions))
                    AlertManager.showWarning('There is no data with these filters.'); //TODO: message

                this.setState({
                    predictions: response.data.success.predictions,
                    activeFilter: { ...filter },
                }, () => {
                    this.updateChart();
                    setLocalStorage(PredictionReport.params.reportKey, "filter", "mainFilter", {
                        month: filter.month,
                        year: filter.year,
                    });
                });
            }

            if (response.data.error) {
                AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED); //TODO: message
            }

        }).catch(error => {
            handleApiError(error);
        }).finally(() => {
            spinner.hide()
        });
    }

    getTitle() {
        const lastDayOfMonth = new Date(+this.state.activeFilter.year, months.indexOf(this.state.activeFilter.month) + 1, 0);

        return `Europe Storage Predictions for ${lastDayOfMonth.getDate()} ${this.state.activeFilter.month} ${this.state.activeFilter.year} `;
    }

    updateChart() {
        if (!this.state.predictions?.length)
            return;

        const chartInstance = this.chartRef?.getEchartsInstance();
        chartInstance.showLoading();
        chartInstance.clear();
        const defaultOptions = this.defaultChartOptions;

        const data = this.state.predictions.map(row => [row.PredictionDate, Math.round(row.Value * 1000) / 1000]);

        const options = {
            ...defaultOptions,
            series: [
                {
                    type: 'line',
                    name: this.getTitle(),
                    data: data,
                }
            ],
            tooltip: {
                trigger: 'axis',
                confine: true,
            },
            legend: {
                padding: [5, 100, 5, 5],
            }
        };

        chartInstance.setOption(options);

        chartInstance.hideLoading();
    }

    onChangeMonth(newMonth: string) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                month: newMonth
            }
        });
    }

    onChangeYear(newYear: string) {
        this.setState({
            selectedFilter: {
                ...this.state.selectedFilter,
                year: newYear
            }
        });
    }

    clearFilters() {
        this.setState({
            selectedFilter: {
                month: months[new Date().getMonth()],
                year: new Date().getFullYear().toString(),
            }
        });
    }

    render() {
        const filterInputProps: { [key: string]: any } = { lg: 1, md: 3, sm: 12, style: { minWidth: '14%' } };
        const filterActionProps: { [key: string]: GridSize } = { sm: 12 };

        return (
            <ReportViewer {...PredictionReport.params} onRefresh={() => this.getData()}>
                <Grid container spacing={1} justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12}>
                        <VPageFilter showHide
                            getActiveFilter={() => ({ ...this.state.activeFilter })}>
                            <Grid item {...filterInputProps}>
                                <VSelect disableClearable
                                    title="Month"
                                    value={this.state.selectedFilter.month}
                                    getOptionLabel={(option) => `${option}`}
                                    options={months}
                                    onChange={(newValue) => this.onChangeMonth(newValue as string)}
                                />
                            </Grid>
                            <Grid item {...filterInputProps}>
                                <VSelect disableClearable
                                    title="Year"
                                    value={this.state.selectedFilter.year}
                                    getOptionLabel={(option) => `${option}`}
                                    options={years}
                                    onChange={(newValue) => this.onChangeYear(newValue as string)}
                                />
                            </Grid>
                            <Grid item
                                {...filterActionProps}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-end">
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => this.clearFilters()}>
                                    Clear
                                </Button>
                                <Button
                                    style={{ height: "40" }}
                                    variant="contained"
                                    onClick={() => this.getData(this.state.selectedFilter)}>
                                    Apply
                                </Button>
                            </Grid>
                        </VPageFilter>
                    </Grid>
                    <Grid item sm={12}>
                        {
                            this.state.predictions?.length ?
                                <VChart
                                    width='99%'
                                    height={320}
                                    title={this.getTitle()}
                                    toolbox={{
                                        chartRef: this.chartRef,
                                        getTableData: () => (
                                            {
                                                columns: ["PredictionDate", "DataType", "Year", "Month", "Day", "Value"],
                                                rows: this.state.predictions
                                            }),
                                    }}
                                    report={
                                        <ReactECharts
                                            ref={(e) => { this.chartRef = e; }}
                                            echarts={echarts}
                                            option={this.defaultChartOptions}
                                            notMerge={true}
                                            lazyUpdate={true}
                                            style={{ width: '99%' }}
                                        />
                                    } >
                                </VChart>
                                :
                                <Typography>No data available</Typography>
                        }
                    </Grid>
                </Grid>
            </ReportViewer>
        )

    }
}

export default PredictionReport;
