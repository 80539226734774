import './FRChartsView.css';
import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Link, Tooltip, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IForecastReportDataResponse } from '../../../apis/vitusApiTypes';
import VBox from '../../../components/VBox/VBox';
import FRTabularView from './FRTabularView';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { GridComponent, GridComponentOption } from 'echarts/components';
import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import VChart from '../../../components/VChart/VChart';
import { VDateTimePicker } from '../../../components/VDatePicker/VDatePicker';
import moment from 'moment';
import { isEmpty, max, mean, min, orderBy, sortBy } from 'lodash';
import _ from 'lodash';
import { createExcel, createExcelWithMultipleSheets, makeTitle, toDateString } from '../../../utils/common';
import AlertManager from '../../../utils/alertManager';
import WarningIcon from '@material-ui/icons/Warning';
import { getDate, isMidnight, getMidnightForDay } from './forecastReportUtils';
import { ProgressionExtensions } from './ProgressionExtensions';

type ECOption = echarts.ComposeOption<GridComponentOption | LineSeriesOption>;

echarts.use(
    [LineChart, GridComponent]
);

interface IState {
    selectedForecastDates: { [key: string]: boolean },
    minPrograssionDate?: Date,
    maxPrograssionDate?: Date,
    showAverageLines: boolean,
    showAverageLineLabels: boolean,
    showExtensionsModal: boolean,
    shownProgressionExtensions: { [key: string]: boolean },
    pivotTable: { [forecastDate: string]: { value: number, date: string }[] },
    dailyAverages: {
        [forecastDate: string]: {
            [dataDate: string]: number | string;
        }
    }
}

export interface IFRChartsViewProps {
    title: string,
    forecasts: IForecastReportDataResponse["success"]["forecasts"],
    warnings?: { [forecastDate: string]: string },
    filters?: string[],
    timeZone: string,
}

const dateFormat = 'yyyy-MM-DD HH:mm';

class FRChartsView extends React.Component<IFRChartsViewProps, IState> {
    forecastDataLineChartRef: ReactECharts | null = null;
    progressionLineChartRef: ReactECharts | null = null;

    state: IState = {
        selectedForecastDates: {},
        showAverageLines: false,
        showAverageLineLabels: false,
        dailyAverages: {},
        showExtensionsModal: false,
        shownProgressionExtensions: this.generateAllExtensionsHiddenDict(),
        pivotTable: {},
    }

    defaultChartOptions: ECOption = {
        animation: false,
        xAxis: {
            type: 'category',
        },
        yAxis: {
            type: 'value',
            scale: true,
        },
        series: [{
            data: [],
            type: 'line'
        }],
    };

    generateAllExtensionsHiddenDict() {
        return Object.fromEntries(Object.entries(ProgressionExtensions).map(([k, v]) => [k, false]));
    }

    makeHtmlTable(data: { columns: string[], rows: { [key: string]: any }[] }, description?: string) {
        const extraHeader = !description ? "" : `
        <thead>
            <tr>
                ${description}
            </tr>
        </thead>`;

        return `
            <table style="width:auto;text-align:left;border:1px solid rgba(224, 224, 224, 1)">
                ${extraHeader}
                <thead>
                    <tr>
                        ${data.columns.map(col => `<th style="border:1px solid rgba(224, 224, 224, 1); white-space: nowrap;">${col}</th>`).join("")}
                    </tr>
                </thead>
                <tbody>
                    ${data?.rows.map(row => `
                    <tr>
                        ${data?.columns.map(col => `
                            <td style="border:1px solid rgba(224, 224, 224, 1); white-space: nowrap;">${row[col] || ""}</td>`).join("")}
                    </tr>
                    `).join("")}
                </tbody>
            </table>
            `;
    }

    getDefaultChartOptions(name: string, xAxisName: string, data: { columns: string[], rows: { [key: string]: any }[], header?: string }[], description?: string) {
        const options: ECOption = { ...this.defaultChartOptions };

        options.toolbox = {
            show: true,
            itemSize: 20,
            z: 1000,
            feature: {
                saveAsImage: {
                    show: true,
                    title: 'Save as Image',
                    name: name,
                    icon: "M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86-3 3.87L9 13.14 6 17h12l-3.86-5.14z",
                },
                dataView: {
                    show: !isEmpty(data),
                    title: 'Show as Table',
                    readOnly: true,
                    optionToContent: () => {
                        if (data.length === 1)
                            return this.makeHtmlTable(data[0], description)

                        const tabView = `
                        <div class="plain-html-tab">
                            ${data.map((table, tableIdx) => `
                            <button 
                                class="plain-html-tablinks ${tableIdx === 0 ? 'active' : ''}" 
                                onclick="openTab(event, '${table.header || name + tableIdx}')">
                                    ${table.header || name}
                            </button>`).join("")}
                        </div>
                        ${data.map((table, tableIdx) => `
                        <div id="${table.header || name + tableIdx}" class="plain-html-tabcontent" style="${tableIdx === 0 ? 'display:block;' : ''}">
                            <span><strong>${table.header || name}</strong></span>
                            ${this.makeHtmlTable(table, description)}
                        </div>
                        `).join("")}
                        `

                        return tabView;
                    }
                },
                myDownloadExcel: {
                    show: true,
                    title: 'Download Excel',
                    icon: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zM211.7 308l50.5-81.8c4.8-8-.9-18.2-10.3-18.2h-4.1c-4.1 0-7.9 2.1-10.1 5.5-31 48.5-36.4 53.5-45.7 74.5-17.2-32.2-8.4-16-45.8-74.5-2.2-3.4-6-5.5-10.1-5.5H132c-9.4 0-15.1 10.3-10.2 18.2L173 308l-59.1 89.5c-5.1 8 .6 18.5 10.1 18.5h3.5c4.1 0 7.9-2.1 10.1-5.5 37.2-58 45.3-62.5 54.4-82.5 31.5 56.7 44.3 67.2 54.4 82.6 2.2 3.4 6 5.4 10 5.4h3.6c9.5 0 15.2-10.4 10.1-18.4L211.7 308z",
                    onclick: () => {
                        if (data.length === 1)
                            createExcel(
                                name,
                                data[0]?.columns,
                                data[0]?.rows?.map(row => (data[0]?.columns || []).map(col => row[col])),
                                description
                            )
                        else
                            createExcelWithMultipleSheets(
                                name,
                                data.map(table => ({
                                    sheetName: table?.header,
                                    headers: table?.columns,
                                    values: table?.rows?.map(row => (table?.columns || []).map(col => row[col])),
                                    description: description
                                }))
                            )
                    }
                },
            },
        }

        options.xAxis = {
            type: 'category',
            name: xAxisName,
            nameLocation: 'middle',
            nameGap: 25,
        };

        return options;
    }

    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `
            function openTab(evt, tabName) {
                let i, tabcontent, tablinks;
            
                tabcontent = document.getElementsByClassName("plain-html-tabcontent");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }
            
                tablinks = document.getElementsByClassName("plain-html-tablinks");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active", "");
                }
            
                document.getElementById(tabName).style.display = "block";
                evt.currentTarget.className += " active";
            }`;
        document.head.appendChild(s);

        if (this.props.forecasts)
            this.updatePivotTable();

        this.calculateDailyAverages(() => this.updateCharts());
    }

    componentDidUpdate(prevProps: IFRChartsViewProps) {
        if (prevProps.forecasts !== this.props.forecasts) {
            this.calculateDailyAverages(() => this.setState({
                selectedForecastDates: {},
                minPrograssionDate: undefined,
                maxPrograssionDate: undefined,
            }, () => this.updateCharts()));

            this.updatePivotTable();
        }
    }

    updatePivotTable() {
        const columns = FRTabularView.getColumns(this.props.forecasts, true);
        const pivotTable: { [forecastDate: string]: { value: number, date: string }[] } = {};
        columns.forEach(column => pivotTable[column] = []);

        this.props.forecasts.filter(forecast => {
            const dataDate = moment(forecast.DateTime, dateFormat).toDate();

            return (!this.state.minPrograssionDate || dataDate >= this.state.minPrograssionDate)
                && (!this.state.maxPrograssionDate || dataDate <= this.state.maxPrograssionDate);
        }).forEach(row => {
            columns.forEach(column => {
                if (row[column] || row[column] === 0)
                    pivotTable[column].push({ value: +row[column], date: row.DateTime });
            })
        });

        this.setState({ pivotTable }, () => this.updateProgressionChart());
    }

    calculateDailyAverages(callback?: () => any) {
        const dayStartPoints: string[] = this.props.forecasts.filter(f => isMidnight(f.DateTime)).map(f => f.DateTime);
        const forecastDates = FRTabularView.getColumns(this.props.forecasts, true)
        const allDailyAverages: IState["dailyAverages"] = {};

        forecastDates.forEach(forecastDate => {
            const currentDailyAverages: { [key: string]: number | string } = {};

            dayStartPoints.forEach(startPoint => {
                const currentDate = getDate(startPoint);
                const currentAvg = mean(this.props.forecasts.filter(f => f[forecastDate] && f.DateTime.startsWith(currentDate)).map(f => f[forecastDate]));
                currentDailyAverages[startPoint] = isNaN(currentAvg) ? "" : Math.round(currentAvg * 100) / 100;
            })

            allDailyAverages[forecastDate] = currentDailyAverages;
        })

        this.setState({ dailyAverages: allDailyAverages }, () => {
            if (callback) callback();
        });
    }

    updateCharts() {
        this.updateForecastDataChart();
        this.updateProgressionChart();
    }

    getSelectedForecastCount() {
        return Object.keys(this.state.selectedForecastDates).filter(selectedDate => this.state.selectedForecastDates[selectedDate]).length;
    }

    shouldShowAverageLines() {
        return this.state.showAverageLines || this.getSelectedForecastCount() === 1;
    }

    shouldShowAverageLineLabels() {
        return this.state.showAverageLineLabels || this.getSelectedForecastCount() === 1;
    }

    updateForecastDataChart() {
        const chartInstance = this.forecastDataLineChartRef?.getEchartsInstance();
        chartInstance.showLoading();
        chartInstance.clear();

        let selectedForecastDates = Object.keys(this.state.selectedForecastDates)
            .filter(f => this.state.selectedForecastDates[f]);

        if (!selectedForecastDates.length)
            selectedForecastDates = FRTabularView.getColumns(this.props.forecasts, true)

        const averageRows: {
            [key: string]: {
                [forecastDate: string]: string | number;
                Date: string;
            }
        } = {};

        Object.keys(this.state.dailyAverages).forEach(forecastDate => {
            Object.keys(this.state.dailyAverages[forecastDate]).forEach(dataDate => {
                if (!averageRows[dataDate])
                    averageRows[dataDate] = { Date: getDate(dataDate) };
                averageRows[dataDate][forecastDate] = this.state.dailyAverages[forecastDate][dataDate];
            })
        })

        const defaultOptions = this.getDefaultChartOptions(`${this.props.title} Comparison`, "Data Date",
            [
                {
                    columns: ['DateTime', ...selectedForecastDates],
                    rows: this.props.forecasts,
                    header: "Hourly Data"
                },
                {
                    columns: ['Date', ...selectedForecastDates],
                    rows: sortBy(Object.keys(averageRows)).map(dataDate => (averageRows[dataDate])),
                    header: "Daily Averages"
                },
            ]);

        chartInstance.setOption({
            ...defaultOptions,
            xAxis: {
                ...defaultOptions.xAxis,
                nameGap: 40,
                axisLabel: {
                    interval: (index: number, value: string) => {
                        if (isMidnight(value))
                            return true;
                        return false;
                    },
                    rotate: 15
                },
                axisTick: {
                },
                splitLine: {
                    show: true,
                },
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                formatter: (params: any) => {
                    const dataDateTime = params[0].axisValue;
                    const dataDate = getDate(dataDateTime);

                    const cellStyle = "border: 1px solid black; padding: 2px 5px;";

                    const tooltip = `
                    <table style="border-collapse: collapse; border-style: hidden;">
                    <thead>
                        <tr style="border: 1px solid black;">
                            <th style="${cellStyle}">Forecast Date</th>
                            <th style="${cellStyle}"><span>${dataDateTime}</span></th>
                            <th style="${cellStyle}"><span>${dataDate} Average</span></th>
                        </tr>
                    </thead>
                        <tbody>
                            ${_(params).sortBy(p => p.seriesName)
                            .reverse()
                            .map((p: any) => {
                                const value = (p.value[1] || p.value[1] === 0) ? p.value[1] : "";
                                const forecastDate = p.seriesName;

                                return (`
                                <tr style="border: 1px solid black;">
                                <td style="${cellStyle}"><span style="background-color: ${p.color}; height: 10px; width: 10px; border-radius: 50%; display: inline-block;"></span>
                                <span>${forecastDate}</span></td>
                                <td style="${cellStyle}"><span><strong style="margin-left:20px; float:right;">${value}</strong></span></td>
                                <td style="${cellStyle}">${this.state.dailyAverages[forecastDate][getMidnightForDay(dataDate)] ?? '-'}</td>
                                </tr>`)
                            }).join('')}
                        </tbody>
                    </table>`

                    return tooltip;
                },
            },
            series: selectedForecastDates.map(forecastDate => {
                const dailyAverages = this.state.dailyAverages[forecastDate];
                const dayStartPoints = sortBy(Object.keys(dailyAverages));

                return {
                    type: 'line',
                    name: forecastDate,
                    data: this.props.forecasts.map(forecast => [forecast.DateTime, forecast[forecastDate]]),
                    symbol: 'none',
                    markLine: this.shouldShowAverageLines() && {
                        symbol: 'none',
                        label: this.shouldShowAverageLineLabels() && {
                            position: "middle",
                            distance: 0,
                        },
                        data: dayStartPoints.slice(0, -1).map((dataDate, idx) => [
                            {
                                name: this.shouldShowAverageLineLabels() && dailyAverages[dataDate],
                                xAxis: dataDate,
                                yAxis: dailyAverages[dataDate]
                            },
                            {
                                xAxis: dayStartPoints[idx + 1],
                                yAxis: dailyAverages[dataDate]
                            }
                        ])
                    }
                }
            }),
            dataZoom: [{
                type: 'inside'
            }],
            legend: {
                type: 'scroll',
                data: selectedForecastDates,
                padding: [5, 90],
            }
        });


        chartInstance.hideLoading();
    }

    updateProgressionChart() {
        const chartInstance = this.progressionLineChartRef?.getEchartsInstance();
        chartInstance.showLoading();
        chartInstance.clear();

        const pivotTable = this.state.pivotTable;

        let { minDataDate, maxDataDate }: { minDataDate: string | Date | null, maxDataDate: string | Date | null } = this.getMinMaxDates();
        minDataDate = this.state.minPrograssionDate ? toDateString(dateFormat, this.state.minPrograssionDate) : minDataDate;
        maxDataDate = this.state.maxPrograssionDate ? toDateString(dateFormat, this.state.maxPrograssionDate) : maxDataDate;

        const detail = `Average of: ${minDataDate} to ${maxDataDate}`
        const data = Object.keys(pivotTable).map(forecast => [forecast, Math.round(mean(pivotTable[forecast].map(f => f.value)) * 1000) / 1000]);
        const maxForecastLength = max(Object.keys(pivotTable).map(forecast => pivotTable[forecast].length)) || 1;
        const pivotTableDates: { [key: string]: { minDate: string, maxDate: string } } = {}

        Object.keys(pivotTable).forEach(p => {
            const dates = pivotTable[p].map(r => r.date)
            pivotTableDates[p] = { minDate: min(dates) || "", maxDate: max(dates) || "" };
        })

        const options: { [key: string]: any } = {
            ...this.getDefaultChartOptions(
                `${this.props.title} Progression`,
                "Forecast Date",
                [{ columns: ['Forecast Date', 'Average'], rows: data.map(d => ({ 'Forecast Date': d[0], 'Average': d[1] })) }],
                detail
            ),
            series: [
                {
                    type: 'line',
                    name: detail,
                    data: data,
                    symbolSize: (value: number[]) => {
                        return pivotTable[value[0]].length * 5 / maxForecastLength;
                    },
                }
            ],
            tooltip: {
                trigger: 'axis',
                confine: true,
            },
            legend: {
                padding: [5, 100, 5, 5],
            }
        }

        options.toolbox.feature["myProgressionExtensions"] = {
            show: true,
            title: "Extensions",
            icon: "M352 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12m96-160v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48m-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6",
            onclick: () => this.setState({ showExtensionsModal: true })
        }

        const shownProgressionExtensions = this.state.shownProgressionExtensions;

        if (!Object.values(shownProgressionExtensions).some(i => i))
            options.tooltip.formatter = (params: any) => {
                const forecast: string = params[0].data[0];
                return `Forecast: ${forecast}
                <br />Average: ${params[0].data[1]}
                <br />Average of ${pivotTable[forecast].length} values
                <br />Min date: ${pivotTableDates[forecast].minDate}
                <br />Max date: ${pivotTableDates[forecast].maxDate}`
            }
        else {
            options.tooltip.valueFormatter = (value: number) => {
                if (Array.isArray(value))
                    return value[value.length - 1];
                return value;
            }

            Object.keys(shownProgressionExtensions).forEach(extensionKey => {
                if (!shownProgressionExtensions[extensionKey])
                    return;

                const extension = ProgressionExtensions[extensionKey];
                extension.addExtensionCharts(options as any, pivotTable);
            });
        }

        chartInstance.setOption(options);

        chartInstance.hideLoading();
    }

    checksChanged(column: string) {
        this.setState({
            selectedForecastDates: {
                ...this.state.selectedForecastDates,
                [column]: !this.state.selectedForecastDates[column]
            }
        }, () => this.updateForecastDataChart())
    }

    getMinMaxDates() {
        const minDataDate = this.props.forecasts?.[0].DateTime || null;
        const maxDataDate = this.props.forecasts?.length ? this.props.forecasts[this.props.forecasts.length - 1].DateTime : null;

        return { minDataDate, maxDataDate };
    }

    validateDates(minDate: Date | undefined, maxDate: Date | undefined) {

        if (minDate && maxDate && minDate > maxDate) {
            AlertManager.showError("Min date must be smaller or equal to max date.");
            return false;
        }

        const { minDataDate, maxDataDate } = this.getMinMaxDates();
        const minDataDateObj = moment(minDataDate)?.toDate();
        const maxDataDateObj = moment(maxDataDate)?.toDate();

        if (minDate) {
            if (minDate < minDataDateObj) {
                AlertManager.showError(`Minimum requested date is ${minDataDate}, to list older data change and apply main filters.`);
                return false;
            }
            if (minDate > maxDataDateObj) {
                AlertManager.showError(`Maximum requested date is ${maxDataDate}, to list newer data change and apply main filters.`);
                return false;
            }
        }

        if (maxDate) {
            if (maxDate < minDataDateObj) {
                AlertManager.showError(`Minimum requested date is ${minDataDate}, to list older data change and apply main filters.`);
                return false;
            }
            if (maxDate > maxDataDateObj) {
                AlertManager.showError(`Maximum requested date is ${maxDataDate}, to list newer data change and apply main filters.`);
                return false;
            }
        }

        return true;
    }

    getFiltersTitle() {
        return this.props.filters ? ` (${this.props.filters.map(f => makeTitle(f)).join("/")})` : "";
    }

    render(): React.ReactNode {
        const columnWidth = 230;
        const rowHeight = 320;
        const boxStyle = { height: rowHeight, width: columnWidth, maxWidth: columnWidth, padding: 8, overflow: 'auto' };

        return (
            <Grid container spacing={1} style={{ padding: '10px 0', }}>
                <Grid item xs={2} style={{ width: columnWidth }}>
                    <VBox style={boxStyle}>
                        <Grid justify="space-between" container>
                            <Grid item>
                                <Typography style={{ fontWeight: 500 }}>
                                    Forecast Dates ({this.props.timeZone})
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href="#" onClick={(e: React.SyntheticEvent) => {
                                    e.preventDefault();
                                    this.setState({ selectedForecastDates: {} }, () => this.updateForecastDataChart())
                                }}>
                                    Clear
                                </Link>
                            </Grid>
                        </Grid>
                        <FormGroup>
                            {
                                orderBy(FRTabularView.getColumns(this.props.forecasts, true), [], ['desc']).map(column => {
                                    return (
                                        <FormControlLabel
                                            key={`check_${column}`}
                                            control={
                                                <Checkbox
                                                    checked={!!this.state.selectedForecastDates[column]}
                                                    onChange={() => this.checksChanged(column)}
                                                    name={`check_${column}`}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography>
                                                    {column}
                                                    {
                                                        this.props.warnings?.[column] &&
                                                        <Tooltip title={<Typography>{this.props.warnings[column]}</Typography>}>
                                                            <WarningIcon fontSize="large" htmlColor="#eed202" style={{ paddingLeft: "5px" }} />
                                                        </Tooltip>
                                                    }
                                                </Typography>
                                            }
                                        />
                                    )
                                })
                            }
                        </FormGroup>
                    </VBox>
                </Grid>
                <Grid item xs={10}>
                    <VChart
                        title={`Forecast Comparison${this.getFiltersTitle()}`}
                        width='99%'
                        height={rowHeight + 40}
                        report={
                            <div >
                                <FormControlLabel key="avg_check" style={{ marginLeft: 10 }}
                                    control={
                                        <Checkbox
                                            checked={this.state.showAverageLines}
                                            onChange={() => this.setState({
                                                showAverageLines: !this.state.showAverageLines,
                                                showAverageLineLabels: !this.state.showAverageLines && this.state.showAverageLineLabels,
                                            }, () => this.updateForecastDataChart())}
                                            name="avg_check"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography>
                                            Show Average Lines
                                        </Typography>
                                    }
                                />
                                <FormControlLabel key="avg_label_check" style={{ marginLeft: 5 }}
                                    control={
                                        <Checkbox
                                            checked={this.state.showAverageLineLabels}
                                            onChange={() => this.setState({
                                                showAverageLines: this.state.showAverageLines || !this.state.showAverageLineLabels,
                                                showAverageLineLabels: !this.state.showAverageLineLabels,
                                            }, () => this.updateForecastDataChart())}
                                            name="avg_label_check"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography>
                                            Show Average Line Labels
                                        </Typography>
                                    }
                                />
                                <ReactECharts
                                    ref={(e) => { this.forecastDataLineChartRef = e; }}
                                    echarts={echarts}
                                    option={this.defaultChartOptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ width: '99%' }}
                                />
                            </div>
                        } >
                    </VChart>
                </Grid>
                <Grid item xs={2}>
                    <VBox style={boxStyle}>
                        <Grid justify="space-between" container>
                            <Grid item>
                                <Typography style={{ fontWeight: 500 }}>
                                    Progression Dates
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href="#" onClick={(e: React.SyntheticEvent) => {
                                    e.preventDefault();
                                    this.setState({ minPrograssionDate: undefined, maxPrograssionDate: undefined }, () => this.updatePivotTable())
                                }}>
                                    Clear
                                </Link>
                            </Grid>
                        </Grid>
                        <VDateTimePicker
                            variant="inline"
                            margin="normal"
                            label="Min Data Date"
                            value={this.state.minPrograssionDate || null}
                            onChange={(date) => {
                                const newDate = date?.toDate();
                                if (this.validateDates(newDate, this.state.maxPrograssionDate))
                                    this.setState({ minPrograssionDate: newDate }, () => this.updatePivotTable())
                            }}
                        />
                        <VDateTimePicker
                            variant="inline"
                            margin="normal"
                            label="Max Data Date"
                            value={this.state.maxPrograssionDate || null}
                            onChange={(date) => {
                                const newDate = date?.toDate();

                                if (this.validateDates(this.state.minPrograssionDate, newDate))
                                    this.setState({ maxPrograssionDate: newDate }, () => this.updatePivotTable())
                            }}
                        />
                    </VBox>
                </Grid>
                <Grid item xs={10}>
                    <VChart
                        width='99%'
                        height={rowHeight}
                        title={`Forecast Progression${this.getFiltersTitle()}`}
                        report={
                            <ReactECharts
                                ref={(e) => { this.progressionLineChartRef = e; }}
                                echarts={echarts}
                                option={this.defaultChartOptions}
                                notMerge={true}
                                lazyUpdate={true}
                                style={{ width: '99%' }}
                            />
                        } >
                    </VChart>
                    {
                        this.state.showExtensionsModal &&
                        <Dialog onClose={() => this.setState({ showExtensionsModal: false })} open={this.state.showExtensionsModal}>
                            <DialogTitle>Extensions</DialogTitle>
                            <DialogContent dividers>
                                <FormGroup>
                                    {
                                        Object.keys(ProgressionExtensions).map(extensionKey => {
                                            const extension = ProgressionExtensions[extensionKey];
                                            const checkedValues = this.state.shownProgressionExtensions;
                                            const currentChecked = checkedValues[extensionKey];

                                            return <FormControlLabel
                                                key={`check${extensionKey}`}
                                                control={
                                                    <Checkbox
                                                        checked={currentChecked}
                                                        onChange={() => {
                                                            this.setState({ shownProgressionExtensions: { ...checkedValues, [extensionKey]: !currentChecked } },
                                                                () => this.updateProgressionChart())
                                                        }}
                                                        name={`check${extensionKey}`}
                                                        color="primary"
                                                    />
                                                }
                                                label={extension.getLabel()}
                                            />
                                        })
                                    }

                                </FormGroup>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState(
                                    { shownProgressionExtensions: this.generateAllExtensionsHiddenDict() },
                                    () => this.updateProgressionChart()
                                )} color="secondary">
                                    Clear All
                                </Button>
                                <Button autoFocus onClick={() => this.setState({ showExtensionsModal: false })}>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
            </Grid>
        );
    }
}

export default FRChartsView;