import { AxiosError } from "axios";
import moment from "moment";
import { SetUserAction } from "../store/actions";
import { store } from "../store/store";
import history from '../system/history';
import AlertManager from "./alertManager";
import messages from "./messages";
import { endpointNames } from "./staticInfo";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export enum ViewTypes {
    Report,
    CustomReport,
    Administration,
}

export const generateUuid = () => {
    //adapted to typescript from: https://stackoverflow.com/a/2117523

    return ((1e7).toString() + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, cStr => {
        const c = Number(cStr);
        return (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16);
    });
};

export const redirectToLogin = () => {
    if (history.location.pathname !== endpointNames.login) {
        store.dispatch<SetUserAction>({ type: 'SET_USER', payload: { userChecked: true } });

        history.push(endpointNames.login, { from: history.location });
    }
}

export const handleApiError = (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
        redirectToLogin();
    }
    else {
        //source:
        //https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

        if (error.response) {
            //backend returned error
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED);
        } else if (error.request) {
            //could not reach backend
            AlertManager.showError(messages.NETWORK_ERROR);
        } else {
            //real unexpexted error
            AlertManager.showError(messages.UNEXPECTED_ERROR_OCCURED);
        }
    }
}

// source:
// https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
// NOT SECURE!
export const simpleHash = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
};

export function getPropertyName<T>() {
    return new Proxy({}, {
        get: (_, prop) => prop,
        set: () => {
            throw Error('Set not supported');
        },
    }) as {
            [P in keyof T]: P;
        };
}

export const shortDateFormat = 'DD/MM/yyyy';
export const longDateFormat = 'DD/MM/yyyy HH:mm';
export const longDateWithSecondsFormat = 'DD/MM/yyyy HH:mm:ss';

export function toDateString(format: string, date?: Date) {
    return moment(date).format(format);
}

export function toShortDateString(date?: Date) {
    return moment(date).format(shortDateFormat);
}

export function toLongDateString(date?: Date) {
    return moment(date).format(longDateFormat)
}

export function shortStringToDate(dateStr?: string) {
    const date = moment(dateStr, shortDateFormat);

    if (date.isValid())
        return date.toDate();
}

export function longStringToDate(dateStr?: string) {
    const date = moment(dateStr, longDateFormat);

    if (date.isValid())
        return date.toDate();
}

export function longStringWithSecondsToDate(dateStr?: string) {
    const date = moment(dateStr, longDateWithSecondsFormat);

    if (date.isValid())
        return date.toDate();
}

const createExcelSheet = (headers?: string[], values?: any[][], description?: string) => {
    const dataArr = [
        headers || [],
        ...(values?.map(row => {
            return row.map(cell => {
                if (!isNaN(cell) && cell !== '')
                    return Number(cell);
                return cell?.toString() || "";
            });
        }) || [])
    ];

    if (description)
        dataArr.splice(0, 0, [description])

    return XLSX.utils.json_to_sheet(dataArr, { skipHeader: true });
};

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const saveWorkBook = (fileName: string, wb: XLSX.WorkBook) => {
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

export const createExcel = (fileName: string, headers?: string[], values?: any[][], description?: string) => {
    const ws = createExcelSheet(headers, values, description);
    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    saveWorkBook(fileName, wb);
};

export const createExcelWithMultipleSheets = (fileName: string, sheets: { headers?: string[], values?: any[][], description?: string, sheetName?: string }[]) => {
    const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };

    sheets.forEach((sheet, idx) => {
        const ws = createExcelSheet(sheet.headers, sheet.values, sheet.description);
        const sheetName = sheet.sheetName?.slice(0, 31) || `Sheet${idx + 1}` // sheet name can not be longer than 31 chars
        wb.Sheets[sheetName] = ws;
        wb.SheetNames.push(sheetName);
    })

    saveWorkBook(fileName, wb);
};

export function arrayNot<T>(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

export function arrayIntersection<T>(a: T[], b: T[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export function arrayUnion<T>(a: T[], b: T[]) {
    return [...a, ...arrayNot(b, a)];
}

export function shallowCopy<T>(objectToCopy: T) {
    return Object.assign({}, objectToCopy) as T;
}
export function shortenNumber(originalNumber: number) {
    let n = originalNumber, sign = "", result;

    if (originalNumber < 0) {
        n = -originalNumber;
        sign = "-";
    }

    if (n > 1000000000000) // shorten as trillion
        result = `${Math.round(n / 10000000000) / 100} T`
    else if (n > 1000000000) // shorten as billion
        result = `${Math.round(n / 10000000) / 100} BN`
    else if (n > 1000000) // shorten as million
        result = `${Math.round(n / 10000) / 100} M`
    else
        result = n.toString();

    return sign + result;
};

export function makeTitle(title: string) {
    return title.replaceAll('_', ' ').split(' ').map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(' ');
}

export const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

export type Month = typeof months[number];

export const shortMonthNames: { [monthNum: string]: string } = {
    "1": "Jan", "2": "Feb", "3": "Mar", "4": "Apr",
    "5": "May", "6": "Jun", "7": "Jul", "8": "Aug",
    "9": "Sep", "10": "Oct", "11": "Nov", "12": "Dec",
}

export const monthNames = months.reduce(
    (dict: { [key: string]: number }, monthName: string, idx: number) => {
        dict[monthName] = idx; return dict;
    }, {});

export const createDateObject = (params: { dayDiffFromNow: number, clearTime: boolean } =
    { dayDiffFromNow: 0, clearTime: false }) => {
    const { dayDiffFromNow, clearTime } = params;
    const customDate = new Date();
    customDate.setDate(new Date().getDate() + dayDiffFromNow);

    if (clearTime)
        customDate.setHours(0, 0, 0, 0);

    return customDate;
}

export function addThousandsDelimeter(num: any, requireNumber: boolean = false) {
    if (isNaN(num) || num == null)
        return num;
    if (requireNumber && typeof num !== "number")
        return num;

    return Number(num).toLocaleString('en-US');
}


export function round(numberToRound: number, roundCount: number = 0) {
    const rounder = Math.pow(10, roundCount);
    return Math.round(numberToRound * rounder) / rounder;
}
